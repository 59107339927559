<template>
  <c-box flexGrow="1">
    <c-breadcrumb
      fontSize="14px"
      lineHeight="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="brand.900"
          as="router-link"
          to="/management/admin"
        >
          Manajemen Admin
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link isCurrent>
          {{ id ? "Ubah" : "Tambah" }} Admin
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Ubah" : "Tambah" }} Admin
    </c-heading>

    <form max-w="590px" v-chakra>
      <c-form-control mb="25px" is-required :isInvalid="firstName === ''">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Depan"
            height="62px"
            v-model="firstName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-input-group size="md">
          <c-input
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Belakang"
            height="62px"
            v-model="lastName"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control :is-invalid="$v.email.$invalid" is-required mb="25px">
        <c-form-label
          fontSize="14px"
          color="#555555"
          fontWeigh="400"
          fontFamily="Roboto"
        >
          Email
        </c-form-label>
        <c-input-group size="md">
          <c-input
            :disabled="id"
            :variant="id ? 'filled' : ''"
            type="email"
            error-border-color="red.300"
            placeholder="Masukkan Email"
            height="62px"
            v-model="email"
          />
        </c-input-group>
      </c-form-control>

      <c-flex mt="28px" justify-content="space-between">
        <c-button
          v-if="!id"
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          v-if="id"
          variant="solid"
          variant-color="red"
          border-radius="60px"
          bg="red.900"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          @click="isOpen = true"
        >
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="brand"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :isDisabled="isDisabledSubmit"
          @click="onSubmit"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>

    <DeleteConfirm
      @on-delete="onDelete"
      :content="deletedText"
      :isOpen="isOpen"
    />
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteConfirm from "./../delete-confirm.vue";
import generalMixin from "@/utils/general-mixins";
import { email } from "vuelidate/lib/validators";

export default {
  name: "SuperadminAdminsForm",
  components: { DeleteConfirm },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
      firstName: null,
      lastName: null,
      email: null,
    };
  },
  computed: {
    ...mapGetters({
      axios: "axios",
      item: "suAdmins/item",
    }),
    id() {
      return this.$route.params.id;
    },
    isDisabledSubmit() {
      if (!this.email || this.$v.email.$invalid || !this.firstName) return true;
      return false;
    },
    deletedText() {
      return `Anda yakin ingin hapus admin <br /> “${this.item.firstName} ${
        this.item.lastName ? " " + this.item.lastName : ""
      }”?`;
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.firstName = val.firstName;
          this.lastName = val.lastName;
          this.email = val.email;
        }
      },
    },
  },
  validations: {
    email: { email },
  },
  methods: {
    ...mapActions({
      createAdmins: "suAdmins/createAdminsSuperadmin",
      detailAdmins: "suAdmins/detailAdminsSuperadmin",
      updateAdmins: "suAdmins/updateAdminsSuperadmin",
      deleteAdmins: "suAdmins/deleteAdminsSuperadmin",
    }),
    onSubmit() {
      let params = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      };
      if (this.id) {
        params.id = this.id;
        return this.updateAdmins(params)
          .then(() => {
            this.$toast({
              title: "Success",
              description: "Update Admin is successfully",
              status: "success",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
            this.$router.push({
              name: "superadmin.admin",
            });
          })
          .catch((err) => {
            this.$toast({
              title: "Failed",
              description: err.data.message ?? "Ops! Something when wrong.",
              status: "error",
              duration: 5000,
              position: "bottom-right",
              variant: "subtle",
            });
          });
      }
      return this.createAdmins(params)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Admin berhasil dibuat.",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "superadmin.admin",
            query: { type: this.type },
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    onCancel() {
      this.$router.push({ name: "superadmin.admin" });
    },
    onDelete(state) {
      this.isOpen = state;
      if (!this.isOpen) return;
      this.deleteAdmins(this.id)
        .then(() => {
          this.$toast({
            title: "Success",
            description: "Admin berhasil dihapus.",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
          this.$router.push({
            name: "superadmin.admin",
          });
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
  },
  async mounted() {
    if (!this.id) return;
    this.detailAdmins(this.id);
  },
};
</script>
